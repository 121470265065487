import React from 'react';

export default () => {
  return (
    <>
      <h1>Impressum</h1>
      <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
      <p>
        Mark Heimer
        <br />
        Dimpfelstra&szlig;e 42
        <br />
        04347 Leipzig
      </p>
      <h2>Kontakt</h2>
      <p>
        Telefon: 015786934232
        <br />
        E-Mail: 13_mark@protonmail.com
      </p>
      <h2>Redaktionell verantwortlich</h2>
      <p>Betreiber der Webiste</p>
      <p>
        Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a>
      </p>
      )
    </>
  );
};
